import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Element } from 'react-scroll';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import GlobalStyle from '../styles/globals';
import {
  AboutHero,
  Avatar,
  ProjectsWrapper,
  Grid,
  Heading,
  Tooling,
  ProjectsGrid,
  AboutInfo,
  Footer,
  StyledParticles,
  HeroConent,
  Hero,
  Logo,
  Indigo,
  MobileIndigo,
  FullIndigo,
  MobileLogoWrapper,
  FooterLink,
  StyledImage,
  RecommendationsWrapper,
  StyledSlider,
  FooterLogos,
  Title,
  HeroText,
  QuoteMark,
  QuoteWrapper,
  ToolBarWrapper,
  StyledLink,
  RecommendationsHeading,
} from '../styles/page-styles';
import { Linkedin, GitHub, Mail } from 'react-feather';
import { styledParticlesConfig } from '../config';

import SEO from '../components/SEO';
import ProjectCard from '../components/ProjectCard';
import profile_photo from '../assets/profile_photo.jpeg';
import quote_mark from '../assets/quote_mark.svg';
import logo from '../assets/logo.svg';
import full_logo from '../assets/indigo42-logo-white.svg';
import 'react-awesome-slider/dist/styles.css';

const Index = () => {
  const { contentfulPortfolioHome } = useStaticQuery(graphql`
    query HomeQuery {
      contentfulPortfolioHome {
        aboutText {
          raw
        }
        tooling {
          image {
            title
            gatsbyImageData
          }
        }
        recommendations {
          title
          description {
            description
          }
        }
        projects {
          title
          technologies
          summary
          industry
          location
          link
        }
      }
    }
  `);

  return (
    <>
      <SEO />
      <GlobalStyle />
      <Hero>
        <ToolBarWrapper>
          <Indigo src={full_logo} alt="Indigo 42 logo" />
          <div>
            <StyledLink href="#about" to="about" smooth={true} duration={500}>
              ABOUT
            </StyledLink>
            <StyledLink href="#tools" to="tools" smooth={true} duration={500}>
              TOOLS
            </StyledLink>
            <StyledLink
              href="#recommendations"
              to="recommendations"
              smooth={true}
              duration={500}
            >
              RECOMMENDATIONS
            </StyledLink>
            <StyledLink
              href="#projects"
              to="projects"
              smooth={true}
              duration={500}
            >
              PROJECTS
            </StyledLink>
          </div>
        </ToolBarWrapper>
        <StyledParticles id="tsparticles" options={styledParticlesConfig} />
        <HeroConent>
          <HeroText>Hello, I&apos;m Joe.</HeroText>
          <HeroText>Senior Full Stack Web Engineer.</HeroText>
          <MobileLogoWrapper>
            <MobileIndigo src={logo} />
          </MobileLogoWrapper>
        </HeroConent>
      </Hero>

      <Element name="about">
        <AboutHero>
          <Heading variant="dark">ABOUT</Heading>
          <AboutInfo>
            <Avatar src={profile_photo} alt="Joe Self photo" />
            <div>
              {documentToReactComponents(
                JSON.parse(contentfulPortfolioHome.aboutText.raw || '')
              )}
            </div>
          </AboutInfo>
        </AboutHero>
      </Element>

      <Element name="tools">
        <Tooling>
          <Heading>TOOLS</Heading>
          <Grid>
            {contentfulPortfolioHome.tooling.map(({ image }) => {
              return (
                <Logo key={image?.title ?? ''}>
                  <StyledImage
                    image={image?.gatsbyImageData}
                    alt={image?.title ?? ''}
                  />
                </Logo>
              );
            })}
          </Grid>
        </Tooling>
      </Element>

      <Element name="recommendations">
        <RecommendationsWrapper>
          <RecommendationsHeading variant="dark">
            RECOMMENDATIONS
          </RecommendationsHeading>
          <StyledSlider>
            {contentfulPortfolioHome.recommendations.map(
              ({ title, description }) => (
                <div key={title}>
                  <QuoteWrapper>
                    <QuoteMark src={quote_mark} alt="Quote mark" />
                    <p>{description.description}</p>
                    <Title>{title}</Title>
                  </QuoteWrapper>
                </div>
              )
            )}
          </StyledSlider>
        </RecommendationsWrapper>
      </Element>

      <Element name="projects">
        <ProjectsWrapper>
          <Heading>PROJECTS</Heading>
          <ProjectsGrid>
            {contentfulPortfolioHome.projects.map(
              (
                { title, summary, link, location, technologies, industry },
                i
              ) => (
                <ProjectCard
                  key={title}
                  title={title}
                  link={link}
                  bg={
                    i % 2 === 0
                      ? 'linear-gradient(to right, rgb(102, 45, 140) 0%, rgb(237, 30, 121) 100%)'
                      : 'linear-gradient(to right, rgb(34, 174, 209) 0%, rgb(109, 142, 160) 100%);'
                  }
                  location={location}
                  technologies={technologies}
                  industry={industry}
                >
                  {summary}
                </ProjectCard>
              )
            )}
          </ProjectsGrid>
        </ProjectsWrapper>
      </Element>

      <Footer id="contact">
        <FullIndigo src={full_logo} alt="Indigo 42 logo" />
        <FooterLogos>
          <FooterLink
            rel="noreferrer"
            aria-label="LinkedIn"
            href="https://www.linkedin.com/in/joe-self/"
            target="_blank"
          >
            <Linkedin size={42} color="#04c2c9" />
          </FooterLink>
          <FooterLink
            aria-label="Github"
            rel="noreferrer"
            href="https://github.com/Joethedeveloper42"
            target="_blank"
          >
            <GitHub size={42} color="#04c2c9" />
          </FooterLink>
          <FooterLink
            aria-label="Email"
            rel="noreferrer"
            href="mailto:joe@joeself.dev?subject=Lets work together!"
            target="_blank"
          >
            <Mail size={42} color="#04c2c9" />
          </FooterLink>
        </FooterLogos>
      </Footer>
    </>
  );
};

export default Index;
