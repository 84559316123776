module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"
  siteTitle: 'Joe Self - Web Developer', // Navigation and Site Title
  siteTitleAlt: 'Joe Self', // Alternative Site title for SEO
  siteTitleShort: 'Joe Self', // short_name for manifest
  siteHeadline: 'Created with gatsby.js starter project', // Headline for schema.org JSONLD
  siteUrl: 'https://joeself.dev', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/indigo42.png', // Used for SEO and manifest
  siteDescription: 'Joe Self - Full Stack Web Developer Portfolio',
  author: 'Joe Self', // Author for schema.org JSONLD
};
