import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import AwesomeSlider from 'react-awesome-slider';
import Particles from 'react-tsparticles';
import { Link } from 'react-scroll';

export const AboutInfo = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

export const Indigo = styled.img`
  width: 150px;
  margin: 0;
  margin-left: 60px;
`;

export const FullIndigo = styled.img`
  width: 200px;
  margin: 20px 0 50px;
`;

export const MobileLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const MobileIndigo = styled.img`
  width: 64px;
  margin: 30px 20px 20px 20px;
  @media (min-width: 800px) {
    display: none;
  }
`;

export const HeroText = styled.p`
  padding: 0 5px;
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
`;

export const ProjectsWrapper = styled.div`
  padding: 150px 100px;
  @media (max-width: 769px) {
    padding: 75px 40px;
  }
`;

export const RecommendationsWrapper = styled.div`
  padding: 150px 0;
  @media (max-width: 769px) {
    padding: 75px 0;
  }
  background-color: #fff;
`;

export const QuoteWrapper = styled.div`
  position: relative;
`;

export const StyledSlider = styled(AwesomeSlider)`
  .awssld__wrapper {
    height: 400px;
    @media (max-width: 1100px) {
      height: 600px;
    }
    @media (max-width: 769px) {
      height: 750px;
    }
  }

  .awssld__content {
    background-color: #fff;
    padding: 0 50px;
    @media (min-width: 769px) {
      padding: 0 250px;
    }
  }
`;

export const StyledTile = styled.div`
  padding: 0 100px;
`;

export const ProjectsGrid = styled.div`
  margin-top: 10px;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

export const Tooling = styled.section`
  padding-top: 150px;
  padding-bottom: 150px;
`;

export const ToolBarWrapper = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
  background-color: transparent;
  padding: 30px 0 30px;
  align-items: center;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  a:last-child {
    margin-right: 60px;
  }
  a {
    position: relative;
    text-decoration: none;
    margin-right: 40px;
    font-size: 18px;
    color: #fff;
    font-weight: 800;
    letter-spacing: 3px;

    &:after {
      position: absolute;
      content: '';
      height: 4px;
      bottom: -4px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 0%;
      background: #fff;
      transition: 0.5s;
    }

    &:hover:after {
      width: 100%;
      background: #fff;
    }
  }
`;

export const FooterLogos = styled.div`
  display: flex;
`;

export const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 75px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Title = styled.p`
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 2px;
  color: #1f2937;
  text-align: center;
`;

export const QuoteMark = styled.img`
  height: 200px;
  position: absolute;
  left: -70px;
  top: -40px;
  opacity: 0.08;
  @media (max-width: 1100px) {
    height: 100px;
    position: absolute;
    left: -20px;
    top: -40px;
  }
  @media (max-width: 768px) {
    height: 100px;
    position: absolute;
    left: -20px;
    top: -40px;
  }
  @media (max-width: 400px) {
    height: 100px;
    position: absolute;
    left: -18px;
    top: -42px;
  }
`;

export const Heading = styled.p`
  font-size: 40px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
  font-weight: 800;
  letter-spacing: 5px;
  color: ${({ variant }) => (variant === 'dark' ? '#444649' : '#fff')};
  text-align: center;
  text-decoration: none;
  position: relative;
  margin-bottom: 75px;

  &:after {
    position: absolute;
    content: '';
    height: 4px;
    bottom: -4px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 6%;
    background: ${({ variant }) => (variant === 'dark' ? '#444649' : '#fff')};
    transition: 0.5s;
  }

  &:hover:after {
    width: 14%;
    background: ${({ variant }) => (variant === 'dark' ? '#444649' : '#fff')};
  }
`;

export const RecommendationsHeading = styled(Heading)`
  @media (max-width: 768px) {
    margin-bottom: 0;
    font-size: 26px;
  }
`;

export const AboutHero = styled.div`
  padding: 150px;
  background-color: #fff;
  @media (max-width: 968px) {
    padding: 75px 40px;
  }
`;

export const Avatar = styled.img`
  width: 12rem;
  margin-right: 20px;
  border-radius: 9999px;
  height: auto;
  @media (max-width: 769px) {
    margin-bottom: 40px;
  }
`;

export const Footer = styled.footer`
  background-color: #1b242f;
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StyledParticles = styled(Particles)`
  position: absolute;
  z-index: -1;
  height: 100vh;
  width: 100%;
`;

export const Hero = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;

export const HeroConent = styled.div`
  color: #fff;
  z-index: 10;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125px;
  @media (max-width: 800px) {
    height: 325px;
  }
  p {
    font-size: 38px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterLink = styled.a`
  margin-right: 35px;
  margin-left: 35px;
`;

export const StyledImage = styled(GatsbyImage)`
  max-width: 110px;
`;
