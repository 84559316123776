import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  width: 100%;
  background: ${(props) => props.bg};
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: translateY(-5px);
  }
  position: relative;
  text-decoration: none;
  border-radius: 0.5rem;
  padding: 2rem;
  --tw-text-opacity: 1;
  transition: transform 0.4s;
`;

const Text = styled.div`
  opacity: 0.75;
  font-family: Raleway, sans-serif;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #fff;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const Title = styled.div`
  color: #fff;
  font-weight: 800;
  font-size: 24px;
  padding-top: 10px;
  text-transform: uppercase;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const ProjectCard = ({
  title,
  children,
  bg,
  link,
  location,
  technologies,
  industry,
}) => (
  <Wrapper href={link} target="_blank" rel="noopener noreferrer" bg={bg}>
    <Title>{title}</Title>
    <Text>
      <b>Summary: </b>
      {children}
    </Text>
    <Text>
      <b>Main technologies:</b> {technologies}
    </Text>
    <Text>
      <b>Industry:</b> {industry}
    </Text>
    <Text>
      <b>Location:</b> {location}
    </Text>
  </Wrapper>
);

export default ProjectCard;
